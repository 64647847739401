import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import Layout from "../components/layout"

import styles from "../styles/pages/jobs.module.scss"
import Hero from "../images/fallbacks/join.jpg"
import HeroWebp from "../images/webp/join.webp"
import HeroSmall from "../images/fallbacks/join-small.jpg"
import HeroSmallWebp from "../images/webp/join-small.webp"

const shortcodes = { Link }

export default function PageTemplate({ data: { mdx } }) {
  return (
    <Layout>
      <section className={styles.positionHeader}>
        <div className={styles.positionHeaderImg}>
          <picture className={styles.heroImgWrapper}>
            <source media="(max-width: 700px)" srcSet={HeroSmallWebp} />
            <source media="(max-width: 700px)" srcSet={HeroSmall} />
            <source media="(min-width: 701px)" srcSet={HeroWebp} />
            <source media="(min-width: 701px)" srcSet={Hero} />
            <img src={Hero} alt="Jenga team working in the office" />
          </picture>
        </div>
        <div className={styles.positionHeaderContent}>
          <h2 className={styles.positionHeaderTitle}>Careers</h2>
          <h3 className={styles.positionHeaderSubtitle}>
            {mdx.frontmatter.title}
          </h3>
        </div>
      </section>
      <section className={styles.position}>
        <ul className={styles.breadcrumb}>
          <li className={styles.breadcrumbItem}>
            <Link to="/join" className={styles.breadcrumbLink}>
              Join Us
            </Link>
          </li>
          <li className={styles.breadcrumbItem}>
            <a href="#" className={styles.breadcrumbLink}>
              {mdx.frontmatter.title}
            </a>
          </li>
        </ul>
        <MDXProvider components={shortcodes} styles={styles}>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query JobPositionsQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
      }
    }
  }
`
